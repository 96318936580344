
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UserState {
  userToken: string | null;
  userName: string  | null;
  userAlias: string | null;

  getUserToken: () => string | null;
  setUserToken: (val: string | null) => void;
  setUserName: (val: string | null) => void;
  setUserAlias: (val: string | null) => void;
  resetUser: () => void;
}


export const UserStore = create<UserState>()(
    persist(
      (set, get) => ({
        userToken: null,
        userName: null,
        userAlias: null,
  
        // user actions
        getUserToken: () => {
          const t = get().userToken
          return t
        },
        setUserToken: (val) => {
          set({userToken: val})
        },
        setUserName: (val) => {
          set({userName: val})
        },
        setUserAlias: (val) => {
          set({userAlias: val})
        },
        resetUser: () => {
          set({userToken: null, userName: null})
        }
      }),
      {
        name: 'user-store'
      }
    )
)

export interface AppState {
  appName: string | null;
  appVersion: string | null;
  online: boolean | null;

  setAppName: (val: string | null) => void;
  setAppVersion: (val: string | null) => void;
  setOnline: (val: boolean | null) => void;
  resetApp: () => void;
}


export const AppStore = create<AppState>()((set, get) => ({
  appName: null,
  appVersion: null,
  online: null,

  // app action
  setAppName: (val) => {
    set({appName: val})
  },
  setAppVersion: (val) => {
    set({appVersion: val})
  },
  setOnline: (val) => {
    set({online: val})
  },
  resetApp: () => {
    set({appName: null, appVersion: null})
  }
}))


export interface GameState {
  gid: string | null;
  title: string | null;
  category: string | null;
  start: string | null;
  end: string | null;
  is_active: boolean | null;

  setGame: (val: GameState | null) => void;
  reset: () => void;
}

export const GameStore = create<GameState>()((set, get) => ({
  gid: null,
  title: null,
  category: null,
  start: null,
  end: null,
  is_active: null,

  setGame: (val) => {
    set({
      gid: val?.gid,
      title: val?.title,
      category: val?.category,
      start: val?.start,
      end: val?.end,
      is_active: val?.is_active
    })
  },
  reset: () => {
    set({
      gid: null,
      title: null,
      category: null,
      start: null,
      end: null,
      is_active: null
    })
  }
}))

export interface PropositionState {
  ref: string;
  label: string;
}

export interface AccessState {
  uid: string;
  name: string;
}


export interface ChallengeState {
  access: [AccessState] | null;
  cid: string | null;
  gid: string | null;
  gain: number | null;
  level: number | null;
  ok_answers: [PropositionState] | [string] | [];
  proposals: [PropositionState] | [];
  question: string | null;
  state: string | null;
  tags: [string] | null;
  timespan: number | null;
  ttl: string | null;
}

export interface ChallengeListState {
  items: ChallengeState[]  | null;
  item: ChallengeState | null;
  loading: boolean;

  setItems: (val: [ChallengeState] | null) => void;
  setItem: (val: ChallengeState | null) => void;
  setOkAnswers: (val: [PropositionState] | [string] | []) => void;
  setState: (val: string) => void;
  setTTL: (val: string) => void;
  setLoading: (val: boolean) => void;
  resetItem: () => void;
  reset: () => void;
}

export const ChallengeStore = create<ChallengeListState>()((set, get) => ({
  items: [] as ChallengeState[],
  item: null,
  loading: false,

  setItem: (val) => {
    set({item: val})
  },
  setItems: (val) => {
    set({items: val})
  },
  setOkAnswers: (val) => {
    const item: ChallengeState | null = get().item
    if(item !== null) {
      item.ok_answers = val
      set({item: item})
    }
  },
  setState: (val) => {
    const item: ChallengeState | null = get().item
    if(item !== null) {
      set({item: {...item, ...{state: val}}})
    }
  },
  setTTL: (val) => {
    const item: ChallengeState | null = get().item
    if(item !== null) {
      set({item: {...item, ...{ttl: val}}})
    }
  },
  setLoading: (val) => {
    set({loading: val})
  },
  resetItem: () => {
    set({item: null})
  },
  reset: () => {
    set({item: null, items: []})
  }
}))

export interface ParticipantState {
  uid: string | null;
  username: string | null;
  is_active: boolean
  name: string | null;
  tags: [string] | null;
}


export interface ParticipantListState {
  participants: ParticipantState[] | null;
  participant: ParticipantState | null;

  setItems: (val: [ParticipantState] | null) =>  void;
  setItem: (val: ParticipantState | null) =>  void;
  reset: () => void;
}


export const ParticipantStore = create<ParticipantListState>()((set, get) => ({
  participants: [] as ParticipantState[],
  participant: null,

  setItem: (val) => {
    set({participant: val})
  },
  setItems: (val) => {
    set({participants: val})
  },
  reset: () => {
    set({participant: null, participants: []})
  }

}))