/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useAxios from "axios-hooks";
import { useIonLoading, useIonAlert } from '@ionic/react';
import { shallow } from "zustand/shallow";

import i18n from '../i18n/i18n';
import { AppStore } from "../stores";
import { ENDPOINTS } from "../constants/api";

export const LoadAppConf = () => {
    const [presentAlert] = useIonAlert();
    const [present, dismiss] = useIonLoading();

    const { setAppName, setAppVersion } = AppStore(
        (state) => ({ setAppName: state.setAppName, setAppVersion: state.setAppVersion }),
        shallow
    )

    const [{data, loading, error}, call] = useAxios({url: ENDPOINTS.APP_CONF}, {manual: true})

    React.useEffect(() => {
        if(loading) {
          present({
            message: i18n.t('initialization'),
            mode: 'md',
            translucent: true
          })
        } else {
          dismiss()
        }
    }, [loading])
    
    React.useEffect(() => {
        if(error) {
            presentAlert({
            header: 'Alert',
            subHeader: i18n.t('systemError'),
            message: i18n.t('errorContactSupport'),
            buttons: ['OK'],
            mode: 'md',
            })
        }
    }, [error])

    React.useEffect(() => {
        if(data) {
            setAppName(data.platform_name)
            setAppVersion(data.version)
        }
    }, [data])

    React.useEffect(() => {
        call()
    }, [])

    return (
        <></>
    )
}