export const APP_CATEGORY: string = 'core.plugins.reqrep'

export const CHALLENGE_OPENED: string = 'opened'
export const CHALLENGE_CLOSED: string = 'closed'
export const CHALLENGE_PENDING: string = 'pending'

export const RESPONSE_PENDING: string = 'pending'
export const RESPONSE_SUCCEEDED: string = 'succeeded'
export const RESPONSE_FAILED: string = 'failed'
export const RESPONSE_SUBMIT: string = 'submit'
export const RESPONSE_OUTDATED: string = 'outdated'