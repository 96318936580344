/* eslint-disable import/no-anonymous-default-export */
export default {
    signIn: 'Authentification',
    initialization: "Initialisation",
    systemError: "Erreur irrécupérable",
    errorContactSupport: "Veuillez contacter l'équipe support",
    login: {
        title: "Formulaire d'authentification",
        inputs: {
            email: "Adresse email",
            emailPlaceHolder: 'Entrez votre adresse email',
            password: "Mot de passe",
            passwordPlaceHolder: "Entrez votre mot de passe",
            missingEmail: 'Veuillez entrer votre email',
            missingPassword: 'Veuillez renseigner votre mot de passe'
        }
    },
    logoutConfirm: {
        title: "Déconnexion",
        msg: "Veuillez confirmer votre demande de déconnexion"
    },
    authError: {
        title: "Erreur d'authentification",
        msg: "Veuillez ré-essayer ou contacter l'équipe support"
    },
    loadingGameFailed: {
        title: "Liste des jeux",
        msg: "Echec de chargement de la liste des jeux. Contacter l'équipe support"
    },
    loadingChallengesFailed: {
        title: "Liste des challenges",
        msg: "Echec de chargement de la liste des challenges. Contacter l'équipe support"
    },
    loadingPlayerFailed: {
        title: "Liste des joueurs",
        msg: "Echec de chargement de la liste des joueurs. Contacter l'équipe support"
    },
    noData: "Pas de données disponibles",
    stateLabel: "Status",
    state: {
        active: "Actif",
        inactive: "Non actif",
        closed: 'Fermé',
        opened: 'Ouvert',
        pending: 'En attente',
        submit: 'Soumission',
        outdated: 'Dépassé',
        failed: 'Echec',
        success: 'Succès'
    },
    challenges: 'Challenges',
    players: 'Joueurs',
    reload: 'Recharger',
    refresh: 'Rafraichir',
    challenge: 'Challenge',
    expectedAnswerNum: 'Nombre de bonne réponse attendue: %{num}',
    start: 'Start',
    stop: 'Stop',
    end: 'Fin',
    filter: 'Filtrer',
    cancel: 'Annuler',
    apply: 'Appliquer',
    gameList: 'Liste des jeux',
    dt_start: 'Début',
    dt_end: 'Fin',
    offline_detected: 'Perte de connexion',
    game: 'Jeu',
    participantList: "Liste des participants",
    close: 'Fermer',
    ranking: "Classement",
    active: 'Actif',
    yes: 'Oui',
    no: 'Non',
    NoParticipant: 'Aucun participant'
}