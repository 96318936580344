import React, { createContext } from "react";
import axios from "axios";
import { configure } from "axios-hooks";

import { UserStore } from "../stores";
import { API_ROOT, HEADER_PREFIX, VERSION } from "../constants/api";


export const AxiosContext = createContext();

export const AxiosProvider = ({ children }) => {
    const getUserToken = UserStore((state) => state.getUserToken)

    axios.defaults.baseURL = API_ROOT

    axios.interceptors.request.use(
        async (config) => {
            const token = getUserToken()

            if(token) {
                config.headers.Authorization = `${HEADER_PREFIX} ${token}`
            } else {
                config.headers.Authorization = null
            }

            config.headers.Accept = `application/json, text/plain, */* version=${VERSION}`
            return config;
        },
        (error) => Promise.reject(error)
    );


    configure({axios: axios, cache: false})

    return (
        <AxiosContext.Provider value={{axios}}>
            {children}
        </AxiosContext.Provider>
    )
}