/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import i18n from '../i18n/i18n';
import GameList from '../components/GameList';
import { ChallengeStore } from '../stores';
import Footer from '../components/Footer';

import '../styles/Home.css';

const Home = () => {
  const reset = ChallengeStore((state) => state.reset)

  React.useEffect(() => {
    console.log('reset challenge list')
    reset()
  }, [])

  return (
    <IonPage id="home">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{i18n.t('gameList')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{i18n.t('gameList')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <GameList />
      </IonContent>

      <Footer />
    </IonPage>
  );
};

export default Home;
