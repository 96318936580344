/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { 
    IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
    IonGrid, IonRow, IonCol, IonCardHeader, IonCard, IonCardSubtitle,
    IonCardTitle, IonCardContent, IonModal, IonFab, IonFabButton, IonButton,
    IonIcon, IonButtons, IonItem, IonLabel, IonSpinner, IonBadge
} from '@ionic/react';
import useAxios from "axios-hooks";
import { peopleOutline, trophyOutline, refreshOutline } from 'ionicons/icons';
import { useHistory } from "react-router";

import { format, groupedData } from "../helpers";
import { GameStore, ChallengeStore, ParticipantStore } from "../stores";
import { ENDPOINTS } from "../constants/api";
import { CHALLENGE_CLOSED, CHALLENGE_OPENED, CHALLENGE_PENDING } from "../constants/config";
import i18n from "../i18n/i18n";
import Footer from "../components/Footer";
import { shallow } from "zustand/shallow";


const GameRanking = ({gid}) => {
    const RANKING_URL = format(ENDPOINTS.RANKING, [gid])
    const [{loading, data}, call] = useAxios({url: RANKING_URL}, {manual: true})
    const [items, setItems] = React.useState([])

    React.useEffect(() => {
        if(data) {
            const arr = data
            arr.sort((a, b) => a.rank - b.rank)
            setItems(arr)
        }
    }, [data])

    React.useEffect(() => {
        call()
    }, [])

    if(loading) {
        return (
            <IonSpinner color='light'></IonSpinner>
        )
    }

    return (
        <>
            <IonFab slot="fixed" vertical="bottom" horizontal="end">
                <IonFabButton onClick={call}>
                    <IonIcon icon={refreshOutline}></IonIcon>
                </IonFabButton>
            </IonFab>
            {
                items.length > 0 ? items.map((value, index) => (
                    <IonItem key={value.uid} className="ion-margin-vertical">
                        <IonLabel>
                            <h1>{value.user}</h1>
                            <p>Points: {value.points}</p>
                        </IonLabel>
                        <IonBadge slot="end" className="ion-padding" style={{fontSize: 20}}>{value.rank}</IonBadge>
                    </IonItem>
                )) : <IonItem>
                        <IonLabel>
                            {i18n.t('NoParticipant')}
                        </IonLabel>
                     </IonItem>
            }
        </>
    )
}


const PingState = ({uid, isActive}) => {
    const [color, setColor] = React.useState('medium')
    const [ret, setRet] = React.useState(null)
    const interval = React.useRef(null)

    const url = `${ENDPOINTS.PLAYERS_PING}?uid=${uid}`
    const [{data}, run] = useAxios(
        {url: url}, {manual: true}
    )

    React.useEffect(() => {
        run()
    }, [])

    React.useEffect(() => {
        if(isActive === false) {
            clearInterval(interval.current)
        }
    }, [isActive])

    React.useEffect(() => {
        interval.current = setInterval(() => {
            if(isActive) run()
        }, 5000);
        return () => clearInterval(interval.current);
    }, [isActive])

    React.useEffect(() => {
        if(data) {
            const results = data?.results || []
            const ret = results.length > 0 ? results[0] : null
            setRet(ret)
        }
    }, [data])

    React.useEffect(() => {
        if(ret) {
            switch(ret?.code) {
                case 'danger':
                    setColor('danger')
                    return
                case 'warn':
                    setColor('warning')
                    return
                case 'ok':
                    setColor('success')
                    return
                default:
                    setColor('medium')
            }
        }
    }, [ret])

    return (
        <IonBadge color={color}>&nbsp;</IonBadge>
    )

}


const GameParticipants = ({gid}) => {
    const PARTICIPANT_URL = format(ENDPOINTS.PLAYERS_LIST, [gid])
    const [{loading, data}] = useAxios(PARTICIPANT_URL)
    const setItems = ParticipantStore((state) => state.setItems)
    const participants = ParticipantStore((state) => state.participants)

    React.useEffect(() => {
        if(data) {
            setItems(data)
        }
    }, [data])

    if(loading) {
        return (
            <IonSpinner color='light'></IonSpinner>
        )
    }

    return (
        participants.length > 0 ? participants.map((value, index) => (
            <IonItem key={value.uid}>
                <IonLabel>
                    <h1>{value.name}</h1>
                    <p>Tags: {(value.tags || []).join(',')} | {i18n.t('active')}: {value.is_active ? i18n.t('yes') : i18n.t('no')}</p>
                </IonLabel>
                <PingState uid={value.uid} isActive={value.is_active} />
            </IonItem>
        )) : <IonItem>
                <IonLabel>
                    {i18n.t('NoParticipant')}
                </IonLabel>
             </IonItem>
    )
}



const ChallengeCard = ({item}) => {

    const history = useHistory();
    const [color, setColor] = React.useState('')
    const setItem = ChallengeStore((state) => state.setItem)

    React.useEffect(() => {
        switch(item.state) {
            case CHALLENGE_OPENED:
                setColor('success')
                break
            case CHALLENGE_PENDING:
                setColor('warning')
                break
            case CHALLENGE_CLOSED:
                setColor('danger')
                break
            default:
                setColor('primary')
        }
    }, [item.state])

    const go = () => {
        setItem(item)
        const url = `/games/${item.gid}/challenges/${item.cid}`
        history.push(url)
    }

    return (
        <IonCard color={color} onClick={go}>
            <IonCardHeader>
                <IonCardTitle>{item.question}</IonCardTitle>
                <IonCardSubtitle style={{fontSize: 12}}></IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
                <div>{`Level ${item.level}`} | {`Gain: ${item.gain}`} | {i18n.t(`state.${item.state}`)}</div>
            </IonCardContent>
        </IonCard>
    )
}


const ChallengeRow = ({items}) => {
    return (
        <IonRow>
            {
                items.map((item, index) => (
                    <IonCol key={`col-${item.cid}`} size="4">
                        <ChallengeCard item={item} key={item.cid} />
                    </IonCol>
                ))
            }
        </IonRow>
    )
}


const GamePage = ({ match }) => {
    const isLoading = ChallengeStore((state) => state.loading)
    const setLoading = ChallengeStore((state) => state.setLoading)
    const CHALLENGES_LIST_URL = format(ENDPOINTS.CHALLENGES_LIST, [match.params.gid])
    const title = GameStore((state) => state.title)
    const [{loading, data}, call] = useAxios({url: CHALLENGES_LIST_URL}, {manual: true})

    const [items, setItems] = React.useState([])
    const [isOpen, setIsOpen] = React.useState(false)
    const [viewRanking, setViewRanking] = React.useState(false)

    const {challengeList, setChallengeList} = ChallengeStore((state) => (
        {
            challengeList: state.items,
            setChallengeList: state.setItems,
        }
    ), shallow)

    React.useEffect(() => {
        setLoading(loading)
    }, [loading])

    React.useEffect(() => {
        if(data) {
            setChallengeList(data?.results)
        }
    }, [data])

    React.useEffect(() => {
        if(challengeList) {
            const grouped = groupedData(challengeList, 3)
            setItems(grouped)
        }
    }, [challengeList])

    if(isLoading) return (
        <IonSpinner color='light'></IonSpinner>
    )

    return (
        <IonPage id="games-list">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{i18n.t('game')}: {title}</IonTitle>
                    <IonButtons slot="primary" className="ion-margin-end">
                        <IonButton fill="solid" onClick={() => setIsOpen(true)}>
                            {i18n.t('players')}
                            <IonIcon slot="end" icon={peopleOutline}></IonIcon>
                        </IonButton>
                        <IonButton fill="solid" onClick={() => setViewRanking(true)}>
                            {i18n.t('ranking')}
                            <IonIcon slot="end" icon={trophyOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">{i18n.t('game')}: {title}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonFab slot="fixed" vertical="bottom" horizontal="end">
                    <IonFabButton onClick={() => {call()}}>
                        <IonIcon icon={refreshOutline}></IonIcon>
                    </IonFabButton>
                </IonFab>

                <IonGrid>
                    {
                        items.map((values, index) => (
                            <ChallengeRow items={values} key={`row-${index}-${values.length}`} />
                        ))
                    }
                </IonGrid>

                <IonModal isOpen={isOpen} backdropDismiss={false}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>{i18n.t('participantList')}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setIsOpen(false)}>{i18n.t('close')}</IonButton>
                        </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding ion-justify-content-center ion-align-items-center">
                        <GameParticipants gid={match.params.gid} />
                    </IonContent>
                </IonModal>

                <IonModal isOpen={viewRanking} backdropDismiss={false}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>{i18n.t('ranking')}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setViewRanking(false)}>{i18n.t('close')}</IonButton>
                        </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding ion-justify-content-center ion-align-items-center">
                        <GameRanking gid={match.params.gid} />
                    </IonContent>
                </IonModal>

            </IonContent>

            <Footer />
        </IonPage>
    )
}

export default GamePage;