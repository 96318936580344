/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useAxios from "axios-hooks";

import { ENDPOINTS } from "../constants/api";
import { GameStore, ChallengeStore } from "../stores";
import { format } from "../helpers";


export const LoadChallengeList = () => {
    const gid = GameStore((state) => state.gid)
    const resetList = ChallengeStore((state) => state.reset)
    const setChallengeList = ChallengeStore((state) => state.setItems)
    const setLoading = ChallengeStore((state) => state.setLoading)
    const [url, setURL] = React.useState<string>('')
    const [{loading, data}, call] = useAxios({url: url}, {manual: true})

    React.useEffect(() => {
        resetList()
        if(gid) {
            const u = format(ENDPOINTS.CHALLENGES_LIST, [gid])
            setURL(u)
        }
    }, [gid])

    React.useEffect(() => {
        if(url) {
            call()
        }
    }, [url])

    React.useEffect(() => {
        setLoading(loading)
    }, [loading])

    React.useEffect(() => {
        if(data) {
            setChallengeList(data?.results)
        }
    }, [data])

    return null
}
