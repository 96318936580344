/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import dayjs from 'dayjs';
import { 
    IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton,
    IonBackButton, IonIcon, IonContent, IonGrid, IonCol, IonRow, IonItem, IonLabel,
    IonCheckbox, IonModal, IonFabButton, IonFab, IonSpinner, IonChip
 } from "@ionic/react";
import { peopleOutline, refreshOutline } from "ionicons/icons";
import useAxios from "axios-hooks";

import {
    CHALLENGE_CLOSED, CHALLENGE_OPENED, CHALLENGE_PENDING,
    RESPONSE_FAILED, RESPONSE_SUCCEEDED, RESPONSE_PENDING, RESPONSE_SUBMIT, RESPONSE_OUTDATED
} from "../constants/config";
import { usePrevious } from "../hooks";
import { ENDPOINTS } from "../constants/api";
import { format } from "../helpers";
import i18n from "../i18n/i18n";
import { ChallengeStore } from "../stores";
import "../styles/ChallengePage.css"
import { shallow } from "zustand/shallow";


const Participants = ({gid, cid}) => {

    const Gain = ({value}) => {
        return (
            <IonChip color={'primary'} style={{fontWeight: 'bold'}}>
                {value} pt(s)
            </IonChip>
        )
    }

    const url = format(ENDPOINTS.CHALLENGE_PLAYERS, [gid, cid])
    const [items, setItems] = React.useState([])
    const [{loading, data}, call] = useAxios({url: url}, {manual: true})

    React.useEffect(() => {
        call()
    }, [])

    React.useEffect(() => {
        if(data) {
            setItems(data)
        }
    }, [data])

    if(loading) return (
        <IonSpinner color='light'></IonSpinner>
    )

    return (
        <>
            <IonFab slot="fixed" vertical="bottom" horizontal="end">
                <IonFabButton onClick={call}>
                    <IonIcon icon={refreshOutline}></IonIcon>
                </IonFabButton>
            </IonFab>
            {
                items.length > 0 ? items.map((item, index) => (
                    <IonItem key={item.uid} className="ion-margin-vertical">
                        <IonLabel>{item.name}</IonLabel>
                        <div className="ion-float-right">
                        {
                            item.state === RESPONSE_SUCCEEDED &&
                            <>
                                <Gain value={item.score} />
                                <IonChip color={'success'}>
                                    {i18n.t('state.success')}
                                </IonChip>
                            </>
                        }
                        {
                            item.state === RESPONSE_PENDING &&
                            <IonChip color={'medium'}>
                                {i18n.t('state.pending')}
                            </IonChip>
                        }
                        {
                            item.state === RESPONSE_SUBMIT &&
                            <IonChip color={'warning'}>
                                {i18n.t('state.submit')}
                            </IonChip>
                        }
                        {
                            item.state === RESPONSE_OUTDATED &&
                            <>
                                <Gain value={0} />
                                <IonChip color={'danger'}>
                                    {i18n.t('state.outdated')}
                                </IonChip>
                            </>
                        }
                        {
                            item.state === RESPONSE_FAILED &&
                            <>
                                <Gain value={0} />
                                <IonChip color={'danger'}>
                                    {i18n.t('state.failed')}
                                </IonChip>
                            </>
                        }
                        </div>
                    </IonItem>
                )) : <IonItem className="ion-justify-content-center">
                        <IonLabel>
                            {i18n.t('NoParticipant')}
                        </IonLabel>
                     </IonItem>
            }
        </>
    )
}


const ActionButton = () => {
    const [cid, gid, state, setState, setTTL] = ChallengeStore((state) => [
        state.item.cid, state.item.gid, state.item.state,
        state.setState, state.setTTL
    ], shallow)
    const [url, setURL] = React.useState(null)
    const [btnColor, setBtnColor] = React.useState(null)
    const [disabled, setDisabled] = React.useState(false)
    const [text, setText] = React.useState('')
    const [{loading, data}, execute] = useAxios({url: url, method: 'patch'}, {manual: true})


    const action = () => {
        if(state === CHALLENGE_CLOSED || !url) return
        execute()
    }

    React.useEffect(() => {
        if(data) {
            setState(data.state)
            setTTL(data.ttl)
        }
    }, [data])

    React.useEffect(() => {
        let u;
        if(state === CHALLENGE_CLOSED) {
            setURL(null)
        } else if (state === CHALLENGE_OPENED) {
            u = format(ENDPOINTS.CLOSE_CHALLENGE, [gid, cid])
            setURL(u)
        } else if (state === CHALLENGE_PENDING) {
            u = format(ENDPOINTS.OPEN_CHALLENGE, [gid, cid])
            setURL(u)
        }

    }, [state])

    React.useEffect(() => {
        switch(state) {
            case CHALLENGE_CLOSED:
                setDisabled(true)
                setBtnColor('medium')
                setText(i18n.t('end'))
                return
            case CHALLENGE_PENDING:
                setText(i18n.t('start'))
                setDisabled(false)
                setBtnColor('success')
                return
            case CHALLENGE_OPENED:
                setText(i18n.t('stop'))
                setDisabled(false)
                setBtnColor('danger')
                return
            default:
        }
    }, [state])

    return (
        <IonButton id='action-btn' expand="block" size="large" disabled={disabled} loading={loading} shape="round" fill="solid" onClick={action} color={btnColor}>
            {text}
        </IonButton>
    )

}


const Timer = ({onComplete, state, cid, ttl, timespan}) => {
    const [value, setValue] = React.useState(timespan)
    const [color, setColor] = React.useState('white')
    const interval = React.useRef(null)

    React.useEffect(() => {
        if(ttl) {
            interval.current = window.setInterval(() => {
                const _ttl = new Date(ttl)
                const _now = new Date()
                const now = dayjs(_now)
                const l_ttl = dayjs(_ttl)
                const diff = l_ttl.diff(now, 's') // seconds
                setValue(diff)
            }, 1000)
        }

        return () => {
            window.clearInterval(interval.current)
        }
    }, [ttl])

    React.useEffect(() => {
        if (state === CHALLENGE_CLOSED) setValue(0) 
    }, [value, state])

    React.useEffect(() => {
        if(value > 10) {
            setColor('white')
        } else {
            setColor('red')
        }
    }, [value])

    React.useEffect(() => {
        if(value === 0) {
            onComplete(true)
        }
        if(value !== null && value < 0) {
            if(interval.current) {
                window.clearInterval(interval.current)
            }
        }
    }, [value])

    if(!cid) return null

    return (
        <p className="ion-text-center" style={{color: color, fontSize: 120, fontWeight: 'bold'}}>
            {
                value === null ? '-': <>
                    {
                        value < 0 ? 0 : value
                    }
                </>
            }
        </p>
    )
}


const Proposal = ({item}) => {
    const {ref, label} = item

    const [ok, setOk] = React.useState(false)
    const [ok_answers] = ChallengeStore((state) => [state.item?.ok_answers])

    React.useEffect(() => {
        if(ok_answers) {
            if(ok_answers.some(o => o?.ref === ref)){
                setOk(true)
            }
        }
    }, [ok_answers])

    return (
        <IonItem style={{width: "100%"}} shape="round" color={ok? "success":"primary"}>
            <IonLabel style={{fontWeight: 'bold'}} className="ion-text-wrap">
                {label}
            </IonLabel>
            <IonCheckbox slot="start" checked={ok} disabled></IonCheckbox>
        </IonItem>
    )
}


const Proposals = ({items}) => {
    return (
        <>
            {
                items.map((value, index) => (
                    <IonRow key={index} className="ion-margin-vertical">
                        <Proposal item={value} key={value.ref}/>
                    </IonRow>
                ))
            }
        </>
    )
}


const ChallengePage = ({match}) => {
    const item = ChallengeStore((state) => state.item)
    const state = ChallengeStore((state) => state.item.state)
    const setOkAnswers = ChallengeStore((state) => state.setOkAnswers)
    const setState = ChallengeStore((state) => state.setState)
    const setTTL = ChallengeStore((state) => state.setTTL)

    const [isOpen, setIsOpen] = React.useState(false)
    const [url, setURL] = React.useState('')
    const prevState = usePrevious(state)
    const [{data}, execute] = useAxios({url: url}, {manual: true})

    const [closeURL, setCloseURL] = React.useState('')
    const [{data: response}, call] = useAxios({url: closeURL, method: 'patch'}, {manual: true})
    const [complete, setComplete] = React.useState(false)

    React.useEffect(() => {
        if(item) {
            const u = format(ENDPOINTS.CHALLENGE_ANSWERS, [item.gid, item.cid])
            setURL(u)

            const cl = format(ENDPOINTS.CLOSE_CHALLENGE, [item.gid, item.cid])
            setCloseURL(cl)
        }
    }, [item])

    React.useEffect(() => {
        if(state === CHALLENGE_CLOSED && prevState === CHALLENGE_OPENED) {
            execute()
        }
    }, [state, prevState])

    React.useEffect(() => {
        setOkAnswers(data)
    }, [data])

    React.useEffect(() => {
        if(complete && state === CHALLENGE_OPENED) {
            call()
        }
    }, [complete, state])

    React.useEffect(() => {
        if(response) {
            setState(response.state)
            setTTL(response.ttl)
        }
    }, [response])

    return (
        <IonPage id="challenge">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                    <IonTitle>{i18n.t('challenge')}: {item.cid}</IonTitle>
                    <IonButtons slot="primary" className="ion-margin-end">
                        <IonButton fill="solid" onClick={() => setIsOpen(true)}>
                            {i18n.t('players')}
                            <IonIcon slot="end" icon={peopleOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <IonGrid>
                    <IonRow>
                        <IonCol size={8}>
                            <IonRow>
                                <IonCol>
                                    <IonItem color="warning">
                                        <IonLabel className="ion-text-wrap">
                                            <h1>{item.question}</h1>
                                            <p>{`Level ${item.level}`} | {`Gain: ${item.gain}`} | {i18n.t(`state.${item.state}`)}</p>
                                        </IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <Proposals items={item.proposals} />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size={4}>
                            <IonRow style={{height: "65%"}}>
                                <IonCol>
                                    <Timer
                                        onComplete={setComplete}
                                        cid={item.cid}
                                        state={item.state}
                                        timespan={item.timespan}
                                        ttl={item.ttl}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow style={{height: "35%"}}>
                                <IonCol>
                                    <ActionButton />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonModal isOpen={isOpen} backdropDismiss={false}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>{i18n.t('participantList')}</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={() => setIsOpen(false)}>{i18n.t('close')}</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding ion-justify-content-center ion-align-items-center">
                        <Participants gid={match.params.gid} cid={match.params.cid} />
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    )
}

export default ChallengePage;