export const format = function(str, data) {
    return str.replace(/{([^{}]+)}/g, function(match, val) {
      var prop = data;
      val.split('.').forEach(function(key) {
        prop = prop[key];
      });
  
      return prop;
    });
};

export const groupedData = (items, by) => {
  const ret = items.reduce((acc, curr, i) => {
      const index = Math.floor(i / by)
      if(!acc[index]) {
          acc[index] = []
      }
      acc[index].push(curr)
      return acc
  }, [])
  return ret
}