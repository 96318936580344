/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { IonFooter, IonToolbar, IonTitle, IonIcon, IonRow, IonCol, IonButton } from '@ionic/react';
import { logOutOutline, homeOutline } from 'ionicons/icons';
import { useLocation } from 'react-router';

import { AppStore, UserStore } from '../stores';
import i18n from '../i18n/i18n';

const Footer = () => {
    const location = useLocation();
    const appVersion = AppStore((state) => state.appVersion)
    const online = AppStore((state) => state.online)
    const resetUser = UserStore((state) => state.resetUser)
    const userToken = UserStore((state) => state.userToken)

    const logout = () => { resetUser() }

    return (
        <IonFooter translucent mode='md'>
            <IonToolbar>
                <IonRow>
                    <IonCol>
                        {userToken && location.pathname !== '/' &&
                            <IonButton className='ion-float-left' size='small' fill="clear" routerLink='/'>
                                <IonIcon icon={homeOutline} style={{fontSize: "20px", color: "green"}}  slot='icon-only' className='ion-no-padding ion-no-margin'/>
                            </IonButton>
                        }
                    </IonCol>
                    <IonCol size='auto'>
                        <IonTitle style={{fontSize: 10}} className="ion-text-center">
                            Halittar product. Version {appVersion} {!online && <span style={{color: 'red', fontWeight: 'bold'}}> [{i18n.t('offline_detected')}]</span>}
                        </IonTitle>
                    </IonCol>
                    <IonCol>
                        {userToken &&
                            <IonButton className='ion-float-right' size='small' fill="clear" onClick={logout}>
                                <IonIcon icon={logOutOutline} style={{fontSize: "20px", color: "red"}}  slot='icon-only' className='ion-no-padding ion-no-margin'/>
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </IonToolbar>
        </IonFooter>    
  )
}

export default Footer;
