/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useAxios from "axios-hooks";

import { UserStore, AppStore } from "../stores";
import { ENDPOINTS } from "../constants/api";

export const Ping = () => {
    const userToken = UserStore((state) => state.userToken)
    const setOnline = AppStore((state) => state.setOnline)

    const [{error}, execute] = useAxios(
        {url: ENDPOINTS.PING, method: 'POST'},
        {manual: true}
    )

    const ping = () => {
        if(!userToken) return
        execute()
    }

    React.useEffect(() => {
        if(error) {
            setOnline(false)
        } else {
            setOnline(true)
        }
    }, [error])

    React.useEffect(() => {
        const interval = setInterval(() => {
            ping()
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    return (
        <></>
    )
}


export const OnLine = () => {
    const setOnline = AppStore((state) => state.setOnline)

    const check = () => {
        if(!navigator.onLine) {
            setOnline(false)
        } else {
            setOnline(true)
        }
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            check()
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    return (
        <></>
    )
}