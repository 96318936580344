/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { AxiosProvider } from './providers/axios';
import { UserStore } from './stores';
import { Ping, OnLine } from './components/Monitor';
import { LoadAppConf } from './components/OnInit';
import { LoadChallengeList } from './components/ChallengeList';

import HomePage from './pages/Home';
import LoginPage from './pages/LoginPage';
import GamePage from './pages/GamePage';
import ChallengeListPage from './pages/ChallengeListPage';
import ChallengePage from './pages/ChallengePage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './styles/App.css'


setupIonicReact();


const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
}

const App = () => {
  const [isAuth, setAuth] = React.useState(false)
  const userToken = UserStore((state) => state.userToken)

  React.useEffect(() => {
    const check = userToken !== null
    setAuth(check)
  }, [userToken])


  return (
    <IonApp style={{ backgroundColor: '#171031' }}>
      <AxiosProvider>
        <LoadAppConf />
        <IonReactRouter>
          <IonRouterOutlet id='main'>
            <Route path="/login" component={LoginPage} />

            <PrivateRoute exact path="/" isAuthenticated={isAuth} component={HomePage} />
            <PrivateRoute exact path="/games/:gid" isAuthenticated={isAuth} component={GamePage} />
            <PrivateRoute exact path="/games/:gid/challenges" isAuthenticated={isAuth} component={ChallengeListPage} />
            <PrivateRoute exact path="/games/:gid/challenges/:cid" isAuthenticated={isAuth} component={ChallengePage} />
          </IonRouterOutlet>
        </IonReactRouter>
        { isAuth ? <Ping /> : null }
        { isAuth ? <LoadChallengeList /> : null}
        <OnLine />
      </AxiosProvider>  
    </IonApp>
  )
}

export default App;
