import { I18n } from "i18n-js";

import fr from './locales/fr';

const i18n = new I18n()

i18n.translations = {fr};
i18n.fallbacks = true;
i18n.defaultLocale = 'fr'
i18n.locale = 'fr'

export default i18n;