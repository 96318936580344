
export const API_ROOT = process.env.NODE_ENV === 'development' ? 'http://api.eqsass.local:8000' : 'https://api.eqsass.com'
export const HEADER_PREFIX = "Token"
export const VERSION = "1.0"
export const ENDPOINTS = {
    APP_CONF: '/app-conf/',
    PING: '/users/ping/',
    STAFF_AUTH: '/users/auth/',
    GAME_LIST: '/games/',
    CHALLENGES_LIST: '/games/{0}/challenges/',
    PLAYERS_LIST: '/games/{0}/participants/',
    RANKING: '/games/{0}/ranking/',
    PLAYERS_PING: '/users/player/ping/',
    CHALLENGE: '/games/{0}/challenges/{1}/',
    CLOSE_CHALLENGE: '/games/{0}/challenges/{1}/close/',
    OPEN_CHALLENGE: '/games/{0}/challenges/{1}/open/',
    CHALLENGE_STATE: '/games/{0}/challenges/{1}/state/',
    CHALLENGE_ANSWERS: '/games/{0}/challenges/{1}/answers/',
    CHALLENGE_PLAYERS: '/games/{0}/challenges/{1}/players/'
}
export const WS_ROOT = process.env.NODE_ENV === 'development' ? 'ws://localhost:8000' : 'ws://api.eqsass.com'